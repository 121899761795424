<template>
  <div class="demand-move-from">
    <div class="demand-move-info">
      <div class="demand-move-info-inp">
        <van-field
          v-model="addData.deviceName"
          label="设备名称"
          placeholder="请输入设备名称"
        />
      </div>
      <div class="demand-move-info-inp">
        <van-field
          v-model="addData.deviceMmodel"
          label="设备型号"
          placeholder="请输入具体型号"
        />
      </div>
      <div class="demand-move-soc">
        <p>主控SOC</p>
        <div>
          <div class="demand-move-soc-list">
            <span
              v-for="el in addData.socList"
              :key="el.itemValue"
              :class="{ active: el.itemValue == addData.socTest }"
              @click="addData.socTest = el.itemValue"
              >{{ el.itemText }}</span
            >
          </div>
          <van-field
            v-if="addData.socTest == 8"
            v-model="addData.socDesc"
            placeholder="请输入"
          />
        </div>
      </div>
      <div class="demand-move-info-inp" style="border: none">
        <van-field
          v-model="addData.socMmodel"
          label="SOC型号"
          placeholder="请输入SOC型号"
        />
      </div>
    </div>
    <!-- 操作系统 -->
    <div class="demand-move-info">
      <div class="demand-move-soc">
        <p>操作系统</p>
        <div>
          <div class="demand-move-soc-list">
            <span
              v-for="el in addData.systemList"
              :key="el.itemValue"
              :class="{ active: el.itemValue == addData.systemTest }"
              @click="addData.systemTest = el.itemValue"
              >{{ el.itemText }}</span
            >
          </div>
          <van-field
            v-if="addData.systemTest == 8"
            v-model="addData.systemDesc"
            placeholder="请输入"
            style="margin-bottom:12px"
          />
        </div>
      </div>
      <div class="demand-move-uploader">
        <p>设备及相关照片：</p>
        <span>支持最多上传三张PNG/JPG格式照片</span>
        <van-uploader
          :after-read="afterRead"
          @delete="uploadDelete"
          v-model="fileList"
          accept="image/*,.png,.jpg,.jpeg"
          multiple
          upload-icon="plus"
          :max-count="3"
        />
      </div>
    </div>
    <div v-if="type == 'add'">
      <van-button block class="btn-red" @click="submitDemand">保存</van-button>
    </div>
    <div v-if="type == 'edit'" class="demand-move-edit-btn">
      <van-button class="del-demand" @click="delDemand">删除</van-button>
      <van-button class="btn-red" @click="submitDemand">保存</van-button>
    </div>
  </div>
</template>

<script>
import { uploadConsult } from "@/api/news";
import {mapGetters} from "vuex";
export default {
  data() {
    return {
      addData: {
        deviceName: "",
        deviceMmodel: "",
        socMmodel: "",
        socList: [],
        socDesc: "",
        systemList: [],
        systemDesc: "",
        fileList: [],
        socTest: "1",
        systemTest: "1",
        uploadList:[]
      },
      fileList:[],
      list:[],
      demandTypeId:'1'
    };
  },
  computed: {
    ...mapGetters(["dict"]),
    type: function () {
      return this.$route.query.type;
    },
    orderId: function () {
      return this.$route.query.orderId;
    },
  },
  mounted() {
    if (this.type == "edit") {
      let index = this.$route.query.index
      let orderList = this.$route.params.list
      this.addData = orderList[index]
      if(orderList[index].uploadList){
        let data = []
        for (let i = 0; i < orderList[index].uploadList.length; i++) {
          let obj = {
            url:`${process.env.VUE_APP_OBS_PREFIX}/${orderList[index].uploadList[i]}`,
            isImage: true,
            index:i
          }
          data.push(obj)
        }
        this.fileList = data
      }
    }
    this.demandTypeId = this.$route.query.demandType
    this.list = this.$route.params.list
    this.addData.socList = this.dict.DEVICE_SOC
    this.addData.systemList = this.dict.DEVICE_OS
  },
  methods: {
    submitDemand() {
      localStorage.removeItem('orderVal')
      let { addData,list,type,demandTypeId,orderId } = this;
      if (addData.deviceName == "") {
        this.$toast("请输入设备名称");
        return;
      } else if (addData.deviceMmodel == "") {
        this.$toast("请输入具体型号");
        return;
      } else if (addData.socTest == "其他" && socDesc == "") {
        this.$toast("请输入主控SOC");
        return;
      } else if (addData.socMmodel == "") {
        this.$toast("请输入SOC型号");
        return;
      } else if (addData.systemTest == "其他" && systemDesc == "") {
        this.$toast("请输入操作系统");
        return;
      }
      if(type == 'edit'){
        let index = this.$route.query.index
        // list.splice(index,1)
        // list.unshift(addData)
        this.list[index] = addData
      }else{
        list.push(addData)
      }
      console.log(list)
      list = JSON.stringify(list)
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      setTimeout(() => {
        this.$toast.clear();
        this.$toast.success('保存成功！');
        this.$router.push({
          name: "demandMove",
          params:{
            form: this.$route.params.form
          },
          query: { type,demandTypeId,orderId,list },
        });
      }, 1500);
    },
    afterRead(file) {
      if(file.length){
        for (let index = 0; index < file.length; index++) {
          this.loadUpload(file[index].file)
        }
      }else{
        this.loadUpload(file.file)
      }
    },
    loadUpload(file){
      var formData = new FormData();
      formData.append("file", file);
      uploadConsult(formData,'4006').then((res) => {
        this.addData.uploadList.push(res.id)
      });
    },
    uploadDelete(e,i){
      this.addData.uploadList.splice(i.index,1)
    },
    delDemand() {
      this.$dialog.confirm({
        title: "标题",
        message: "确定删除？",
      })
      .then(() => {
        let index = this.$route.query.index
        let {list,type,demandTypeId,orderId} = this
        list.splice(index,1)
        this.$toast("删除成功");
        list = JSON.stringify(list)
        setTimeout(() => {
          this.$router.push({
            name: "demandMove",
            params:{
              form: this.$route.params.form
            },
            query: { type,demandTypeId,orderId,list },
          });
        }, 1500);
      })
      .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.demand-move-from {
  min-height: calc(100vh - 56px);
  background: #f6f6f6;
  padding: 16px;
  .demand-move-info {
    background: #fff;
    border-radius: 4px;
    padding: 0 12px;
    margin-bottom: 12px;
    .demand-move-info-inp {
      border-bottom: 1px solid #eee;
    }
    .demand-move-soc {
      > p {
        line-height: 58px;
        font-size: 14px;
        color: #333;
        margin-bottom: 0;
      }
      .demand-move-soc-list {
        display: flex;
        flex-wrap: wrap;
        > span {
          width: 99px;
          height: 38px;
          border: 1px solid #eeeeee;
          border-radius: 19px;
          text-align: center;
          line-height: 38px;
          margin-right: 10px;
          font-size: 12px;
          color: #333333;
          margin-bottom: 11px;
          transition: all 0.3s;
          &:nth-child(3n) {
            margin-right: 0;
          }
          &.active {
            background: #EA0B06;
            color: #fff;
          }
        }
      }
    }
    .demand-move-uploader {
      padding-bottom: 10px;
      > p {
        font-size: 14px;
        line-height: 20px;
        color: #333333;
        margin-bottom: 12px;
      }
      > span {
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: #333333;
        margin-bottom: 12px;
      }
    }
  }
  .demand-move-edit-btn {
    display: flex;
    > button {
      flex: 1;
    }
    .del-demand {
      flex: none;
      width: 96px;
      background: rgba(185, 56, 56, 0.1);
      margin-right: 10px;
      color: #EA0B06;
      border: none;
    }
  }
}
</style>

<style>
.demand-move-info-inp .van-cell::after {
  display: none;
}
.demand-move-info-inp .van-cell {
  padding: 17px 0;
  font-size: 14px;
}
.demand-move-info-inp .van-field__control {
  text-align: right;
}
.demand-move-soc .van-cell {
  background: #f9f9fb;
}
</style>
